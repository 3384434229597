import React, { ReactElement } from 'react';

import { AppBar, Box, Container, useScrollTrigger } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useConfig from 'hooks/useConfig';
import HorizontalMenuList from 'layout/MainLayout/HorizontalBarMenuList';

interface ElevationScrollProps {
  children: ReactElement;
  window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window!
  });

  theme.shadows[4] = theme.customShadows.z1;

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

const CustomAppBar = () => {
  const theme = useTheme();

  const { container } = useConfig();

  return (
    <ElevationScroll>
      <AppBar
        sx={{
          top: 71,
          bgcolor: theme.palette.background.paper,
          width: '100%',
          height: 62,
          justifyContent: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          zIndex: 1098
        }}
      >
        <Container maxWidth={container ? 'lg' : false}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HorizontalMenuList />
          </Box>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default CustomAppBar;
