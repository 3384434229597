import { combineReducers } from 'redux';

import accountReducer from 'store/slices/account';
import accountApiReducer from 'store/slices/account-api';
import botReducer from 'store/slices/bot';
import chatReducer from 'store/slices/chat';
import contentReducer from 'store/slices/content';
import dashboardReducer from 'store/slices/dashboard';
import menuReducer from 'store/slices/menu';
import profileReducer from 'store/slices/profile';
import snackbarReducer from 'store/slices/snackbar';

const reducer = combineReducers({
  accountApi: accountApiReducer,
  snackbar: snackbarReducer,
  menu: menuReducer,
  chat: chatReducer,
  bot: botReducer,
  content: contentReducer,
  profile: profileReducer,
  account: accountReducer,
  dashboard: dashboardReducer
});

export default reducer;
