import { useRoutes } from 'react-router-dom';

import AuthenticationRoutes from 'routes/AuthenticationRoutes';
import LoginRoutes from 'routes/LoginRoutes';
import MainRoutes from 'routes/MainRoutes';
import MarketingRoutes from 'routes/MarketingRoutes';

export default function ThemeRoutes() {
  return useRoutes([MarketingRoutes, LoginRoutes, AuthenticationRoutes, MainRoutes]);
}
