import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';

const initialState = {
  bots: [],
  loading: false,
  choices: {
    response_choices: [],
    language_choices: [],
    default_prompt: '',
    model_choices: []
  },
  createField: {
    bot_name: '',
    bot_description: '',
    model: 'gpt-3.5-turbo',
    ai_nickname: '',
    ai_title: '',
    organization_name: '',
    temperature: 1,
    ai_output_focus: ['Answering Questions', 'Brainstorming Ideas', 'Innovative Solutions'],
    unable_to_answer_response: '',
    ai_language: '',
    top_p: 1,
    n: 1,
    max_tokens: 150,
    max_length: 1,
    presence_penalty: 0,
    frequency_penalty: 0,
    advance_mode: false,
    image: '',
    allowed_directories: [],
    prompt: '',
    prompt_type: '',
    id: '',
    created: '',
    modified: '',
    first_message: ''
  },
  currentBot: {
    id: '',
    bot_name: '',
    bot_description: '',
    model: '',
    ai_nickname: '',
    ai_title: '',
    organization_name: '',
    temperature: 1,
    ai_output_focus: [],
    unable_to_answer_response: '',
    ai_language: '',
    top_p: 0,
    n: 0,
    max_tokens: 0,
    max_length: 0,
    presence_penalty: 0,
    frequency_penalty: 0,
    advance_mode: false,
    image: '',
    allowed_dirs: [],
    prompt: '',
    prompt_type: '',
    created: '',
    modified: '',
    api_key: '',
    bot_color: '',
    first_message: ''
  }
};

const bot = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    setCreateField: (state: any, action) => {
      const { field, value } = action.payload;
      state.createField[field] = value;
    },
    setLoader(state, action) {
      state.loading = action.payload;
    },
    getBots(state, action) {
      state.bots = action.payload;
    },
    clearBotFields() {
      return initialState;
    },
    setChoices(state, action) {
      state.choices = action.payload;
    },
    setCurrentBot(state, action) {
      state.currentBot = action.payload;
    },
    setCurrentBotField(state: any, action) {
      const { field, value } = action.payload;
      state.currentBot[field] = value;
    }
  }
});

export default bot.reducer;

export const { setCreateField, clearBotFields, setChoices, setCurrentBot, setCurrentBotField } = bot.actions;

export function getAllBots() {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.get('/api/botconfig/list');
      dispatch(bot.actions.getBots(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot get bots',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}

export function createBot(bot_data: any) {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.post('api/botconfig/create/', bot_data);
      await dispatch(getAllBots());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data?.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return response;
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : 'Couldnot create bot',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}

export function retrieveBot(botId: number) {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.get(`api/botconfig/${botId}/`);
      dispatch(bot.actions.setCurrentBot(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not get current bot',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}

export function updateBot(botId: number, updatedData: any) {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.put(`api/botconfig/${botId}/`, updatedData);
      await dispatch(getAllBots());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not update bot',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}
export function deleteBot(botId: number) {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.delete(`api/botconfig/${botId}/`);
      dispatch(getAllBots());

      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not delete bot',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}

export function getChoices() {
  return async () => {
    try {
      dispatch(bot.actions.setLoader(true));
      const response = await axios.get(`api/choices/`);
      dispatch(bot.actions.setChoices(response.data));
    } catch (error) {
    } finally {
      dispatch(bot.actions.setLoader(false));
    }
  };
}
