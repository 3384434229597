import axios from 'axios';

import { BACKEND_URL } from 'config';

const axiosServices = axios.create({ baseURL: BACKEND_URL });

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
