import { memo } from 'react';

import NavItem from 'layout/MainLayout/MenuList/NavItem';
import menuItem from 'menu-items';

const HorizontalMenuList = () => {
  const navItems = menuItem.items.map((group) =>
    group.type === 'group' && group.children
      ? group.children
          .filter((item) => item.type === 'item')
          .map((item) => <NavItem key={item.id} item={item} level={1} parentId={item.id ?? ''} />)
      : null
  );

  return <>{navItems}</>;
};
export default memo(HorizontalMenuList);
