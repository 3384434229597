import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import { DefaultRootStateProps } from 'types';
import { ChatMessagePayload } from 'types/chat';
import axios from 'utils/axios';

const initialState: DefaultRootStateProps['chat'] = {
  error: null,
  chats: {
    user_chat_sessions: [],
    anony_chat_sessions: []
  },
  loader: false,
  chat_messages_loader: false,
  current_chat: {
    id: 0,
    chat_messages: [],
    created: '',
    modified: '',
    active: false,
    user: 0,
    bot: 0,
    name: ''
  }
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getUserChatsSuccess(state, action) {
      state.chats = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
    getCurrentChat(state, action) {
      state.current_chat = action.payload;
    },
    addMessage(state, action: PayloadAction<ChatMessagePayload>) {
      state.current_chat.chat_messages.push(action.payload);
    },
    recieveMessage(state, action) {
      const { newMessage, response } = action.payload;
      state.current_chat.chat_messages[newMessage.id - 1] = response;
    },
    removeMessage(state) {
      state.current_chat.chat_messages.pop();
    },
    setChatLoader(state, action) {
      state.chat_messages_loader = action.payload;
    },
    setSessionName(state, action) {
      const { id, name, sessionType } = action.payload;
      const sessionKey = sessionType === 'user' ? 'user_chat_sessions' : 'anony_chat_sessions';

      state.chats[sessionKey] = state.chats[sessionKey].map((session) => {
        if (session.id === id) {
          return { ...session, name };
        }
        return session;
      });
    },

    deleteChatSession(state, action) {
      const { id, sessionType } = action.payload;
      const sessionKey = sessionType === 'user' ? 'user_chat_sessions' : 'anony_chat_sessions';

      state.chats[sessionKey] = state.chats[sessionKey].filter((session) => session.id !== id);
    }
  }
});

export const { addMessage, recieveMessage, removeMessage, setSessionName, deleteChatSession } = slice.actions;

export default slice.reducer;

export function getUserChats(botID: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.get(`/api/botconfig/${botID}/chatsession/list`);
      dispatch(slice.actions.getUserChatsSuccess(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not get user chats',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function insertNewChat(question: string, bot_id: any) {
  return async () => {
    try {
      const response = await axios.post(`/api/botconfig/${bot_id}/chat/`, { question });
      await dispatch(getUserChats(bot_id));
      return response.data;
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}
export function insertChat(question: string, chatsession_id: string, bot_id: string) {
  return async () => {
    try {
      const response = await axios.post(`/api/botconfig/${bot_id}/chat/`, { question, chatsession_id });
      return response.data;
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}
export function saveSessionName(name: string, sessionID: number, sessionType: string) {
  return async () => {
    try {
      const response = await axios.put(`/api/chatsession/${sessionID}/`, { name });
      if (response.status === 200) dispatch(setSessionName({ id: sessionID, name: name, sessionType: sessionType }));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}
export function deleteSession(sessionId: number, sessionType: string) {
  return async () => {
    try {
      const response = await axios.delete(`/api/chatsession/${sessionId}/`);
      if (response.status === 200) {
        dispatch(deleteChatSession({ id: sessionId, sessionType: sessionType }));
      }
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Coulnot delete session',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}

export function getCurrentChatSession(sessionId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setChatLoader(true));
      const response = await axios.get(`/api/chatsession/${sessionId}/`);
      dispatch(slice.actions.getCurrentChat(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Coulnot get current session',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setChatLoader(false));
    }
  };
}
