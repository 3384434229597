import { createSlice } from '@reduxjs/toolkit';

import { MAX_FILE_SIZE } from 'store/constant';
import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';

const initialState = {
  directories: [],
  loader: false,
  currentDirectory: {
    id: 0,
    account: 0,
    title: '',
    parent: null,
    child_directories: [],
    directory_data_files: [],
    created: '',
    modified: ''
  },
  currentContent: {
    file_name: '',
    text_content: ''
  }
};

const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    getDirectoriesData(state, action) {
      state.directories = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
    setCurrentDirectory(state, action) {
      state.currentDirectory = action.payload;
    },
    setCurrentContent(state, action) {
      state.currentContent = action.payload;
    },
    updateCurrentContent(state: any, action) {
      const { field, value } = action.payload;
      state.currentContent[field] = value;
    }
  }
});

export const { updateCurrentContent } = slice.actions;

export default slice.reducer;

export function getDirectories() {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.get(`/api/directory/`);
      dispatch(slice.actions.getDirectoriesData(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot get directories',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
export function createParentDirectory(title: string) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post(`/api/directory/`, { title });
      await dispatch(getDirectories());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data?.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : 'Couldnot get directory',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
export function createSectionDirectory(title: string, parent: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post(`/api/directory/`, { title, parent });
      dispatch(getCurrentDirectory(parent));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data?.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : 'Couldnot create directory',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
export function createSubSectionDirectory(title: string, parent: number, directoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post(`/api/directory/`, { title, parent });
      dispatch(getCurrentDirectory(directoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data?.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : 'Couldnot create directory',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
export function uploadByUrl(
  directory: number,
  data_file_link: string,
  file_type: string,
  file_name: string,
  directoryId: number
) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post(`/api/datafile/upload/`, {
        directory,
        data_file_link,
        file_type,
        file_name
      });
      dispatch(getCurrentDirectory(directoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error: any) {
      const { detail } = error;

      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
export function uploadFiles(data: any, directoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post(`/api/datafile/upload/`, data);
      dispatch(getCurrentDirectory(directoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error: any) {
      const { detail } = error;
      dispatch(
        openSnackbar({
          open: true,
          message: detail ? detail : error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function deleteDirectory(directoryId: number, rootDirectoryId: number, parent: Boolean = false) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.delete(`api/directory/${directoryId}/`);
      if (!parent) await dispatch(getCurrentDirectory(rootDirectoryId));
      if (parent) await dispatch(getDirectories());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot delete directory',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function updateDirectory(directoryId: number, title: string, rootDirectoryId: number, parent: Boolean = false) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.patch(`api/directory/${directoryId}/`, { title: title });
      if (!parent) await dispatch(getCurrentDirectory(rootDirectoryId));
      if (parent) await dispatch(getDirectories());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot directory name',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function getCurrentDirectory(directoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.get(`api/directory/${directoryId}/`);
      dispatch(slice.actions.setCurrentDirectory(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot get current directory',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function deleteDatafile(datafileId: number, rootDirectoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.delete(`api/datafile/${datafileId}/`);
      await dispatch(getCurrentDirectory(rootDirectoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot delete file',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function updateDatafile(datafileId: number, file_name: string, rootDirectoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.patch(`api/datafile/${datafileId}/`, { file_name: file_name });
      await dispatch(getCurrentDirectory(rootDirectoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot update file name',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export async function importCourse(files: any, setLoader: Function) {
  if (files) {
    if (files.size <= MAX_FILE_SIZE) {
      setLoader(true);
      const data = new FormData();
      data.append('zip_file', files as File, files.name);
      try {
        const res = await axios.post('/api/import/course/', data);
        if (res && res.status === 201) {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Course imported sucessfully',
              variant: 'alert',
              alert: {
                color:'success'
              },
              close: false
            })
          );
        }
      } catch (err: any) {
        dispatch(
          openSnackbar({
            open: true,
            message: (err?.length && err[0]) || 'invalid zip file added',
            variant: 'alert',
            alert: {
              color: 'primary'
            },
            close: false
          })
        );
      } finally {
        setLoader(false);
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'course file size should be less than 50MB',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  }
}

export function writeDocument(data: any, rootDirectoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.post('api/datafile/write/', { ...data });
      await dispatch(getCurrentDirectory(rootDirectoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error:any) {
      dispatch(
        openSnackbar({
          open: true,
          message: error?.detail? error?.detail:error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function updateDocument(document_id: string, data: any, rootDirectoryId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.patch(`api/datafile/edit/${document_id}/`, { ...data });
      await dispatch(getCurrentDirectory(rootDirectoryId));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}

export function retreieveDocument(document_id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoader(true));
      const response = await axios.get(`api/datafile/${document_id}`);
      dispatch(slice.actions.setCurrentContent(response.data));
      return response;
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: error,
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(slice.actions.setLoader(false));
    }
  };
}
