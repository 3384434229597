import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { DASHBOARD_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';

const RoleBasedGuard = ({ children }: GuardProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== 'owner') {
      navigate(DASHBOARD_PATH, { replace: true });
    }
  }, [user, navigate]);

  return children;
};

export default RoleBasedGuard;
