import { StyledEngineProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { clientId } from 'config';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import NavigationScroll from 'layout/NavigationScroll';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Snackbar from 'ui-component/extended/Snackbar';
import Locales from 'ui-component/Locales';

import './assets/css/css-overwrites.css';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeCustomization>
        <Locales>
          <NavigationScroll>
            <GoogleOAuthProvider clientId={clientId}>
              <AuthProvider>
                <>
                  <Routes />
                  <Snackbar />
                </>
              </AuthProvider>
            </GoogleOAuthProvider>
          </NavigationScroll>
        </Locales>
      </ThemeCustomization>
    </StyledEngineProvider>
  );
};

export default App;
