import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';

const initialState = {
  name: '',
  email: '',
  image: '',
  errors: {
    'User errors:': {
      email: []
    },
    'New password': [],
    'Old password': ''
  }
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUpdateField: (state: any, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    hasError(state: any, action: any) {
      state.errors = action.payload;
    },
    setProfileData: (state, action) => {
      const { name, email, image } = action.payload;
      state.name = name;
      state.email = email;
      state.image = image;
    },
    clearError(state, action) {
      const fieldName = action.payload;
      (state.errors as any)[fieldName] = '';
    },
    clearAllErrors(state) {
      state.errors = {
        'User errors:': {
          email: []
        },
        'New password': [],
        'Old password': ''
      };
    }
  }
});

export default profile.reducer;

export const { setUpdateField, clearError, clearAllErrors } = profile.actions;

export function updateProfile(data: any) {
  return async () => {
    try {
      const response = await axios.put('/api/me/', data);
      dispatch(profile.actions.setUpdateField(response.data));
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return response;
    } catch (error: any) {
      dispatch(profile.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could update profile',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}

export function updatePassword(data: any) {
  return async () => {
    try {
      const response = await axios.post('/api/update_password/', data);
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return response;
    } catch (error: any) {
      dispatch(profile.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not update password',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}

export function getProfile() {
  return async () => {
    try {
      const response = await axios.get('/api/me/');
      dispatch(profile.actions.setProfileData(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Could not get profile',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    }
  };
}
