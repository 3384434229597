import { IconDashboard, IconFiles, IconRobot } from '@tabler/icons-react';
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

const icons = {
  IconRobot,
  IconFiles,
  IconDashboard
};

const Pages: NavItemType = {
  id: 'chatly',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'bots',
      title: <FormattedMessage id="bots" />,
      type: 'item',
      url: '/bots',
      icon: icons.IconRobot,
      breadcrumbs: false,
      children: [
        {
          id: 'Create',
          title: <FormattedMessage id="Create your bot with purpose" />,
          type: 'item',
          url: '/bots/create',
          icon: icons.IconRobot,
          breadcrumbs: true
        }
      ]
    },
    {
      id: 'courses',
      title: <FormattedMessage id="courses" />,
      type: 'item',
      url: '/courses',
      icon: icons.IconFiles,
      breadcrumbs: false,
      children: []
    }
  ]
};

export default Pages;
