import { Avatar, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconHelp } from '@tabler/icons-react';

import Mixpanel from 'utils/mixpanel';

const HelpButton = () => {
  const theme = useTheme();

  const handleButtonClick = () => {
    window.open('https://help.edly.io/docs/chatly');
    Mixpanel.track('help_button_click');
  };

  return (
    <Box sx={{ display: 'flex', alignItems:'center' }} style={{ paddin: '10px' }}>
      <Button
        disableElevation
        variant="contained"
        sx={{ marginRight: '20px' }}
        onClick={() => {
          // @ts-ignore
          window.Storylane.Play({
            type: 'popup',
            demo_type: 'image',
            width: 2560,
            height: 1196.4444444444443,
            scale: '0.95',
            demo_url: 'https://app.storylane.io/demo/ts3kwdibp3dx',
            padding_bottom: 'calc(46.74% + 27px)'
          });
        }}
      >
        Take a Demo
      </Button>
      <Avatar
        variant="rounded"
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          border: '1px solid',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.dark,
          background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
          color: theme.palette.warning.main,
          transition: 'all .2s ease-in-out',
          '&:hover': {
            borderColor: theme.palette.warning.light,
            background: theme.palette.warning.main,
            color: theme.palette.warning.dark
          }
        }}
        onClick={handleButtonClick}
        color="inherit"
      >
        <IconHelp />
      </Avatar>
    </Box>
  );
};

export default HelpButton;
