import LAYOUT_CONST from 'constant';
import { ConfigProps } from 'types/config';

export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';
export const HORIZONTAL_MAX_ITEM = 6;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
export const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const STRIPE_CUSTOMER_PORTAL_LINK = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK;
export const NODE_ENV = process.env.NODE_ENV;
export const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const EMBED_APP_URL = process.env.REACT_APP_EMBED_APP_URL
export const captchaSitekey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;

const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT,
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER,
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light',
  presetColor: 'default',
  locale: 'en',
  rtlLayout: false,
  container: false
};

export default config;
