import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import { InviteFieldsType } from 'types/account';
import axios from 'utils/axios';

const initialState = {
  users: [],
  errors: [
    {
      non_field_errors: ''
    }
  ],
  isLoading: false
};

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    getUsers(state, action) {
      state.users = action.payload;
    },
    hasError(state: any, action: any) {
      state.errors = action.payload;
    },
    setLoader(state, action) {
      state.isLoading = action.payload;
    },
    clearError(state) {
      state.errors = [];
    },
    clearFieldError(state, action) {
      const { index } = action.payload;
      if (state.errors[index]) {
        state.errors[index].non_field_errors = '';
      }
    }
  }
});

export default account.reducer;

export const { clearError, clearFieldError } = account.actions;

export function getUsers() {
  return async () => {
    try {
      dispatch(account.actions.setLoader(true));
      const response = await axios.get('api/users/list/');
      dispatch(account.actions.getUsers(response.data));
      return response;
    } catch (error: any) {
      dispatch(account.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot get users',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(account.actions.setLoader(false));
    }
  };
}

export function inviteUsers(data: InviteFieldsType[]) {
  return async () => {
    try {
      dispatch(account.actions.setLoader(true));
      const response = await axios.post('api/invite/', data);
      await dispatch(getUsers());
      dispatch(
        openSnackbar({
          open: true,
          message: response.data,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return response;
    } catch (error: any) {
      dispatch(account.actions.hasError(error));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot invite users',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(account.actions.setLoader(false));
    }
  };
}
