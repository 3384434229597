import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';

const initialState = {
  aiApiKey: '',
  loading: false
};

const accountApi = createSlice({
  name: 'accountApi',
  initialState,
  reducers: {
    setAiApiKey: (state: any, action) => {
      state.aiApiKey = action.payload;
    },
    setLoader(state, action) {
      state.loading = action.payload;
    }
  }
});

export default accountApi.reducer;

export const { setAiApiKey, setLoader } = accountApi.actions;

export function updateAiApiKey(api_key: string, account_id: any) {
  return async () => {
    try {
      dispatch(accountApi.actions.setLoader(true));
      const response = await axios.patch(`api/accounts/${account_id}/`, {
        ai_api_key: api_key
      });
      dispatch(accountApi.actions.setAiApiKey(response.data.ai_api_key));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Updated AI API Key',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Please try again',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(accountApi.actions.setLoader(false));
    }
  };
}

export function getAiApiKey(account_id: any) {
  return async () => {
    try {
      dispatch(accountApi.actions.setLoader(true));
      const response = await axios.get(`api/accounts/${account_id}/`);
      dispatch(accountApi.actions.setAiApiKey(response.data.ai_api_key));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Error fetching API key.',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(accountApi.actions.setLoader(false));
    }
  };
}
