import React, { createContext, useEffect, useReducer } from 'react';

import { googleLogout } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

import accountReducer from 'store/accountReducer';
import { LOGIN, LOGOUT } from 'store/actions';
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import Mixpanel from 'utils/mixpanel';

const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken: (st: string) => boolean = (access) => {
  if (!access) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(access);

  return decoded.exp > Date.now() / 1000;
};

const setSession = (access?: string | null) => {
  if (access) {
    localStorage.setItem('access', access);
    axios.defaults.headers.common.Authorization = `Bearer ${access}`;
  } else {
    localStorage.removeItem('access');
    delete axios.defaults.headers.common.Authorization;
  }
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  async function refetchUser() {
    try {
      const { data } = await axios.get('/api/me/');
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: data
        }
      });
    } catch (err) {
      dispatch({
        type: LOGOUT
      });
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const access = window.localStorage.getItem('access');
        if (access && verifyToken(access)) {
          setSession(access);
          const { data } = await axios.get('/api/me/');
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: data
            }
          });
          Mixpanel.identify(data.account_user_id);
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();

    const broadcastChannel = new BroadcastChannel('auth');
    const handleMessage = (event: any) => {
      if (event.data.type === 'logout') {
        dispatch({
          type: LOGOUT
        });
      }
    };

    broadcastChannel.addEventListener('message', handleMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleMessage);
    };
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/login/', { email, password });
    const { access } = response.data;
    setSession(access);
    const { data } = await axios.get('/api/me/');
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: data
      }
    });
  };

  const register = async (email: string, password: string, name: string) => {
      const response = await axios.post('/api/signup/', {
        email,
        password,
        name
      });
      if (response.status === 201) {
        Mixpanel.signup(response.data.user.id, email, name, "email");
        return response.data.message;
      }
  };

  const googleSignUp = async (token: string) => {
    try {
      const response = await axios.post('/api/google/signup/', { token });
      if (response.status === 200) {
        const { access } = response.data;
        setSession(access);
        const { data } = await axios.get('/api/me/');
        Mixpanel.signup(data.account_user_id, data.email, data.name, "google");
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: data
          }
        });
      }
      return '';
    } catch (err: any) {
      return err.error;
    }
  };

  const googleSignIn = async (token: string) => {
    try {
      const response = await axios.post('/api/google/signin/', { token });
      if (response.status === 200) {
        const { access } = response.data;
        setSession(access);
        const { data } = await axios.get('/api/me/');
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: data
          }
        });
      }
      return '';
    } catch (err: any) {
      return err.error;
    }
  };

  const logout = async () => {
    const item = localStorage.getItem('GoogleUser') || '';
    if (item.length) {
      localStorage.removeItem('GoogleUser');
      googleLogout();
    }

    await axios.post('/api/logout/');
    setSession(null);
    dispatch({ type: LOGOUT });

    const broadcastChannel = new BroadcastChannel('auth');
    broadcastChannel.postMessage({ type: 'logout' });
  };

  const resetPassword = async (email: string) => {
    await axios.post('api/password_link/', { email: email });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        googleSignIn,
        googleSignUp,
        logout,
        register,
        resetPassword,
        updateProfile,
        refetchUser
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
