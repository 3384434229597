import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store/index';
import { openSnackbar } from 'store/slices/snackbar';
import { DashboardProps } from 'types/dashboard';
import axios from 'utils/axios';

const initialState: DashboardProps = {
  dashboardData: {
    total_chats: 0,
    total_chatbots: 0,
    total_folders: 0,
    popular_bots: [],
    chats_per_month: [],
    chats_per_day: [],
    chats_per_year: [],
    total_queries: 0
  },
  loader: false
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardData(state, action) {
      state.dashboardData = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    }
  }
});

export default dashboard.reducer;

export function getDashboardData() {
  return async () => {
    try {
      dispatch(dashboard.actions.setLoader(true));
      const response = await axios.get('/api/dashboard/');
      dispatch(dashboard.actions.setDashboardData(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Couldnot get dashboard data',
          variant: 'alert',
          alert: {
            color: 'primary'
          },
          close: false
        })
      );
    } finally {
      dispatch(dashboard.actions.setLoader(false));
    }
  };
}
