import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from 'config';

mixpanel.init(MIXPANEL_TOKEN as string);

const getDistinctId = (userId: number) => {
  return `USER_ID_${userId}`;
};

const Mixpanel = {
  track: (eventName: string, properties?: any, user_id?: number) => {
    if (user_id) {
      mixpanel.identify(getDistinctId(user_id));
    }
    mixpanel.track(eventName, properties);
  },
  identify: (user_id: number) => {
    mixpanel.identify(getDistinctId(user_id));
  },
  signup: (user_id: number, email: string, user_name: string, source: string = 'email' || 'google') => {
    mixpanel.identify(getDistinctId(user_id));
    mixpanel.people.set({
      $distinct_id: getDistinctId(user_id),
      $email: email,
      $name: user_name
    });
    mixpanel.track('signup', {
      email: email,
      name: user_name,
      source: source
    });
  }
};

export default Mixpanel;
