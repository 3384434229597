import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';

const MarketingHome = Loadable(lazy(() => import('views/pages/marketing')));
const MarketingUsecase = Loadable(lazy(() => import('views/pages/marketing/usecases')));
const MarketingPricing = Loadable(lazy(() => import('views/pages/marketing/pricing')));
const MarketingContact = Loadable(lazy(() => import('views/pages/marketing/contact')));
const MarketingLayout = Loadable(lazy(() => import('layout/MarketingLayout')));

const MainRoutes = {
  path: '/',
  element: <MarketingLayout />,
  children: [
    {
      path: '/',
      element: <MarketingHome />
    },
    {
      path: '/usecases',
      element: <MarketingUsecase />
    },
    {
      path: '/pricing',
      element: <MarketingPricing />
    },
    {
      path: '/contact',
      element: <MarketingContact />
    }
  ]
};

export default MainRoutes;
