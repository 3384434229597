import { lazy } from 'react';

import { Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleBasedRoute from 'utils/route-guard/RoleBaseGuard';

const Bots = Loadable(lazy(() => import('views/pages/bots')));
const Help = Loadable(lazy(() => import('views/pages/help')));
const Chats = Loadable(lazy(() => import('views/pages/chat')));
const CourseImport = Loadable(lazy(() => import('views/pages/courses/courseImport')));
const Account = Loadable(lazy(() => import('views/pages/settings')));
const CreateBot = Loadable(lazy(() => import('views/pages/bots/create-bot')));
const EditBot = Loadable(lazy(() => import('views/pages/bots/edit-bot')));
const Content = Loadable(lazy(() => import('views/pages/courses')));
const EditContent = Loadable(lazy(() => import('views/pages/courses/EditCourse')));
const Write = Loadable(lazy(() => import('views/pages/courses/CreateDocument')));
const Edit = Loadable(lazy(() => import('views/pages/courses/EditDocument')));
const Upload = Loadable(lazy(() => import('views/pages/courses/UploadDocument')));
const AddByUrl = Loadable(lazy(() => import('views/pages/courses/AddByUrl')));
const UserProfile = Loadable(lazy(() => import('views/pages/user-profile')));
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const EmbedBot = Loadable(lazy(() => import('views/pages/bots/embed-bot')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/bots/:botId/chats',
      element: <Chats />
    },
    {
      path: '/bots',
      element: <Bots />
    },
    {
      path: '/bots/:botId/chats/:chatId',
      element: <Chats />
    },
    {
      path: '/bots/create',
      element: <CreateBot />
    },
    {
      path: '/bots/:botId/edit',
      element: <EditBot />
    },
    {
      path: '/bots/:botId/embed-bot',
      element: <EmbedBot />
    },
    {
      path: '/courses',
      element: <Content />
    },
    {
      path: '/courses/import',
      element: <CourseImport />
    },
    {
      path: '/courses/:id',
      element: <EditContent />
    },
    {
      path: '/courses/:id/section/:secId',
      element: <EditContent />
    },
    {
      path: '/courses/:id/section/:secId/subsection/:subSecId',
      element: <EditContent />
    },

    {
      path: '/courses/:id/write',
      element: <Write />
    },
    {
      path: '/courses/:id/section/:secId/write',
      element: <Write />
    },
    {
      path: '/courses/:id/section/:secId/subsection/:subSecId/write',
      element: <Write />
    },
    {
      path: '/courses/:id/upload',
      element: <Upload />
    },
    {
      path: '/courses/:id/section/:secId/upload',
      element: <Upload />
    },
    {
      path: '/courses/:id/section/:secId/subsection/:subSecId/upload',
      element: <Upload />
    },
    {
      path: '/courses/:id/addbyurl',
      element: <AddByUrl />
    },
    {
      path: '/courses/:id/section/:secId/addbyurl',
      element: <AddByUrl />
    },
    {
      path: '/courses/:id/section/:secId/subsection/:subSecId/addbyurl',
      element: <AddByUrl />
    },
    {
      path: '/courses/:id/file/:fileId/edit',
      element: <Edit />
    },
    {
      path: '/courses/:id/section/:secId/file/:fileId/edit',
      element: <Edit />
    },
    {
      path: '/courses/:id/section/:secId/subsection/:subSecId/file/:fileId/edit',
      element: <Edit />
    },
    {
      path: '/account/users',
      element: (
        <RoleBasedRoute>
          <Account />
        </RoleBasedRoute>
      )
    },
    {
      path: '/account/billing',
      element: (
        <RoleBasedRoute>
          <Account />
        </RoleBasedRoute>
      )
    },
    {
      path: '/account/api',
      element: (
        <RoleBasedRoute>
          <Account />
        </RoleBasedRoute>
      )
    },
    {
      path: '/help',
      element: <Help />
    },
    {
      path: '/profile',
      element: <UserProfile />
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />
    }
  ]
};

export default MainRoutes;
