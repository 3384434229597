import { ElementType, Suspense } from 'react';

import Loader from 'ui-component/Loader';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
